const config = {
  APP_URL: import.meta.env.VITE_APP_URL,
  API_URL: import.meta.env.VITE_API_URL,
  WS_URL: import.meta.env.VITE_WS_URL,
  ENV: import.meta.env.VITE_ENV ?? "development",
  LS_PRODUCT: import.meta.env.VITE_LS_PRODUCT,
  SENTRY_RELEASE: import.meta.env?.VITE_SENTRY_RELEASE,
  EXPERIMENTAL_USERS: (import.meta.env?.VITE_EXPERIMENTAL_USERS ?? "").split(
    ",",
  ),
} as const;

const isDevelopment = config.ENV === "development";
const isProduction = config.ENV === "production";

export { config, isDevelopment, isProduction };
