import ky, { KyInstance, KyResponse, Options } from "ky";

import { config } from "#root/config";

/**
 * TYPES
 **/
export type APISingleObject<T> = {
  data: T;
};

export type APICollection<T> = {
  data: T[];
};

export type APIFormError = {
  errors: {
    form: {
      [errorName: string]: string | undefined;
      global?: string;
    };
  };
};

export type APIError = {
  errors: {
    details: string;
  };
};

export type User = {
  id: string;
  displayName: string;
  email: string;
  credits: number;
};

export type Session = {
  id: string;
  name: string;
  doneAt: string | null;
  insertedAt: string;
  updatedAt: string;
  questionsCount: number;
  aiEnabled: boolean;
  ratingSubmitted: boolean;
};

export type Question = {
  id: string;
  question: string;
  insertedAt: string;
  respondedAt: string | null;
  skippedAt: string | null;
  twitchUsername: string;
  isFocused: boolean;
};

export type Transaction = {
  id: string;
  lemonSqueezyId: string;
  quantity: number;
  insertedAt: string;
  totalFormatted: string;
};

export const baseOptions: Options = {
  prefixUrl: `${config.API_URL}/api`,
  credentials: "include",
  headers: {
    accept: "application/json",
    ["content-type"]: "application/json",
  },
  retry: 0,
};

export const defaultClient = ky.create(baseOptions);

/**
 * Append the specified path to the configured API base URL
 */
export function getApiUrl(path: `/${string}`) {
  return `${config.API_URL}/api${path}`;
}

export type MetaResponse = APISingleObject<{ csrf: string }>;
export function fetchMeta(
  apiClient: KyInstance,
  options: { includeResponse: false },
): Promise<MetaResponse>;
export function fetchMeta(
  apiClient: KyInstance,
  options: { includeResponse: true },
): Promise<{ data: MetaResponse; response: KyResponse }>;
export async function fetchMeta(
  apiClient: KyInstance = defaultClient,
  options: { includeResponse: boolean },
) {
  const response = await apiClient.get("meta");
  const data = await response.json<MetaResponse>();

  return options.includeResponse ? { data, response } : data;
}

type UserResponse = APISingleObject<User>;
export function fetchUser(apiClient: KyInstance = defaultClient) {
  return apiClient.get("auth/me").json<UserResponse>();
}

type SocketTokenResponse = APISingleObject<{ token: string }>;
export function fetchSocketToken(apiClient: KyInstance = defaultClient) {
  return apiClient.get("auth/socket/token").json<SocketTokenResponse>();
}

export type CreateSessionData = {
  name?: string;
  aiEnabled?: boolean;
};
type CreateSessionResponse = APISingleObject<Session>;
export function createNewSession(
  apiClient: KyInstance = defaultClient,
  data: CreateSessionData,
) {
  return apiClient
    .post("sessions", { json: data })
    .json<CreateSessionResponse>();
}

export type SessionsResponse = APICollection<Session>;
export function fetchSessions(apiClient: KyInstance = defaultClient) {
  return apiClient.get(`sessions`).json<SessionsResponse>();
}

export type SessionResponse = APISingleObject<Session>;
export function fetchSession(
  apiClient: KyInstance = defaultClient,
  id: string,
) {
  return apiClient.get(`sessions/${id}`).json<SessionResponse>();
}

export type QuestionsResponse = APICollection<Question>;
export function fetchSessionQuestions(
  apiClient: KyInstance = defaultClient,
  id: string,
) {
  return apiClient.get(`sessions/${id}/questions`).json<QuestionsResponse>();
}

export type QuestionReponse = APISingleObject<Question>;
export function postSessionNextQuestion(
  apiClient: KyInstance = defaultClient,
  sessionId: string,
) {
  return apiClient
    .post(`sessions/${sessionId}/questions/next`)
    .json<"" | QuestionReponse>();
}

export function postSessionClearQuestion(
  apiClient: KyInstance = defaultClient,
  sessionId: string,
) {
  return apiClient
    .post(`sessions/${sessionId}/questions/clear`)
    .json<"" | QuestionReponse>();
}

export function postSessionSkipQuestion(
  apiClient: KyInstance = defaultClient,
  sessionId: string,
  questionId: string,
) {
  return apiClient
    .post(`sessions/${sessionId}/questions/${questionId}/skip`)
    .json<"">();
}

export function postSessionFocusQuetion(
  apiClient: KyInstance = defaultClient,
  sessionId: string,
  questionId: string,
) {
  return apiClient
    .post(`sessions/${sessionId}/questions/${questionId}/focus`)
    .json<"" | QuestionReponse>();
}

export function deleteSessionTerminate(
  apiClient: KyInstance = defaultClient,
  sessionId: string,
) {
  return apiClient.delete(`sessions/${sessionId}`).json<SessionResponse>();
}

export type TransactionsResponse = APICollection<Transaction>;
export function fetchTransactions(apiClient: KyInstance = defaultClient) {
  return apiClient.get("transactions").json<TransactionsResponse>();
}

export type RateSessionData = null | {
  rate: number;
  comment?: string;
};
export function postSessionRate(
  apiClient: KyInstance = defaultClient,
  sessionId: string,
  data: RateSessionData,
) {
  return apiClient
    .post(`sessions/${sessionId}/rate`, { json: data ? data : {} })
    .json<SessionResponse>();
}
